import React from "react";
import styles from "./styles.module.scss";
import { DirectusImage } from "app/core/common";

export default function ProfileInfos() {
  return (
    <div className={styles.profile}>
      <>
        <div
          className={styles.header}
          style={{
            backgroundImage: `url("https://loop-markets.directus.app/assets/80f89a22-b030-45e7-9e7c-d4fbcecef0cd")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <div className={styles.profile_header}>
          <div className={styles.avatar}>
            <img
              src={
                "https://loop-markets.directus.app/assets/b2181db2-9c47-47fb-8fc0-96039695dea2"
              }
              alt="profile_loop_image"
            />
          </div>
          <div className={styles.name}>
            <span>BLOND:ISH</span>
          </div>
        </div>
      </>
    </div>
  );
}

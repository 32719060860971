import React from "react";
import styles from "./styles.module.scss";
import PostItem from "./PostItem";
import ProfileInfos from "./ProfileInfos";

export default function Feed2() {
  const posts = [
    {
      artistImage:
        "https://loop-markets.directus.app/assets/b2181db2-9c47-47fb-8fc0-96039695dea2",
      artistName: "Blond:ish",
      postDate: "5 min ago",
      postMessage: "Summer ain't over",
      postImage:
        "https://loop-markets.directus.app/assets/d57e08dc-e1f3-4b9a-8f31-504236af7c20",
    },
    {
      artistImage:
        "https://loop-markets.directus.app/assets/b2181db2-9c47-47fb-8fc0-96039695dea2",
      artistName: "Blond:ish",
      postDate: "1 hour ago",
      postMessage: "Cooking up some beats in the Lab",
      postImage:
        "https://loop-markets.directus.app/assets/128e5628-3737-4d16-b3c1-09438cf9a43f",
    },
  ];
  return (
    <div className={styles.mainFeed}>
      <ProfileInfos />
      <div className={styles.mainFeed__line}></div>
      {posts.map((post, index) => (
        <PostItem post={post} />
      ))}
    </div>
  );
}

import React from "react";
import styles from "./styles.module.scss";
import { FaRegHeart } from "react-icons/fa";
import { FaRegComment } from "react-icons/fa";

export default function PostItem({ post }) {
  return (
    <div className={styles.postItem}>
      <div className={styles.postItem__header}>
        <img src={post.artistImage} alt="profile_pic" />
        <div>
          <p>{post.artistName}</p>
          <span>{post.postDate}</span>
        </div>
      </div>
      <div className={styles.postItem__message}>{post.postMessage}</div>
      <div className={styles.postItem__img}>
        <img src={post.postImage} alt="post_image" />
      </div>
      <div className={styles.postItem__reactions}>
        <div>
          <FaRegHeart />
        </div>
        <div>
          <FaRegComment />
        </div>
      </div>
    </div>
  );
}

import Button from "app/components/button/button";
import styles from "./styles.module.scss";
import { FaArrowLeft } from "react-icons/fa6";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";

export default function Collection2() {
  const history = useHistory();
  const collectioImage =
    "https://loop-markets.directus.app/assets/0b435ef5-f72c-4efd-a09c-f511a33cf78a";

  const benefitImage1 =
    "https://loop-markets.directus.app/assets/79a19632-8d89-418f-84a9-77010230dfb8";
  const benefitImage2 =
    "https://loop-markets.directus.app/assets/9432547b-25ef-4b9e-b831-dddb8511aec9";
  const videoImage =
    "https://loop-markets.directus.app/assets/ee0e6ae1-05a7-4cfd-99fb-eee8e0cb7bce";

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <div className={styles.collection}>
      <div className={styles.back} onClick={() => handleGoBack()}>
        <FaArrowLeft />
        <span>back</span>
      </div>
      <div className={styles.collection__info}>
        <div className={styles.collection__info_image}>
          <LazyLoadImage
            src={collectioImage}
            effect="blur"
            wrapperClassName={styles.image}
          />
        </div>
        <div className={styles.collection__info_text}>
          <div className={styles.status}>LIMITED</div>
          <div>
            <div className={styles.collectionName}>Different Way</div>
            <div className={styles.type}>SINGLE</div>
          </div>
          <div className={styles.message}>
            Special Edition Music Collectible Featuring exclusive media
          </div>
          <div className={styles.line}></div>
          <div className={styles.price}>$7,99</div>
          <button>BUY</button>
        </div>
      </div>
      <div className={styles.collection__benefits}>
        <div className={styles.collection__benefits_title}>EXCLUSIVES</div>
        <div className={styles.collection__benefits_benefit}>
          <span>Single</span>
          <div className={styles.imageInfo}>
            <LazyLoadImage
              src={benefitImage1}
              effect="blur"
              wrapperClassName={styles.image}
            />
            <div>
              <p>Different Way</p>
              <p>PREVIEW</p>
            </div>
          </div>
        </div>
        <div className={styles.collection__benefits_benefit}>
          <span>Remixes</span>
          <div className={styles.imageInfo}>
            <LazyLoadImage
              src={benefitImage2}
              effect="blur"
              wrapperClassName={styles.image}
            />
            <div>
              <p>Different Way</p>
              <p>3 TRACKS</p>
            </div>
          </div>
        </div>
        <div className={styles.collection__benefits_benefitVideo}>
          <span>Behind-the-scenes</span>
          <div className={styles.imageInfo}>
            <LazyLoadImage
              src={videoImage}
              effect="blur"
              wrapperClassName={styles.image}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

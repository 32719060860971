import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./discover.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { CreatorCollections } from "app/core/common";
import CollectionSlider from "app/components/discover/collections/collectionSlider";
import SmartVinil from "app/components/discover/SmartVinil";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";

export default function DiscoverView() {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [creators, setCreators] = useState([]);
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [genre, setGenre] = useState(1);
  const [playingSong, setPlayingSong] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    async function fetchCreators() {
      setLoading((prev) => !prev);
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}fans/creators`,
          headers: {
            "Content-Type": "application/json",
            page: page,
            limit: 16,
            query: `featured: { _eq: true }`,
          },
        });
        const data = await response.data;
        setCreators(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    async function fetchSongs() {
      setLoading((prev) => !prev);
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/leaderboard/latest?limit=1&page=1`,
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.data;
        setSongs(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading((prev) => !prev);
      }
    }

    async function fetchCollectibles() {
      setLoading((prev) => !prev);
      try {
        const response = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/collections?limit=8&page=1`,
          headers: {
            "Content-Type": "application/json",
            query: `featured: { _eq: true }`,
          },
        });
        const data = await response.data;
        setCollections(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading((prev) => !prev);
      }
    }

    fetchCreators();
    //fetchSongs();
    //fetchCollectibles();
  }, [page]);

  const handleOpenCollection = () => {
    history.push("/collection/1");
  };

  return (
    <div className={styles.discover}>
      <div className={styles.banner} onClick={() => handleOpenCollection()}>
        <div className={styles.title}>
          <h1>SPOTLIGTH</h1>
        </div>
        <LazyLoadImage
          src="https://loop-markets.directus.app/assets/1f3a8fbb-42b8-4c9b-b1a5-f1f2f1e1fdaa"
          alt="collections-loop-fans"
          effect="blur"
          wrapperClassName={styles.image}
        />
      </div>
      <div className={styles.collection_slider}>
        <div className={styles.title}>
          <h1>RECENT RELEASES</h1>
        </div>
        <CollectionSlider />
      </div>
      <div className={styles.artist_slider}>
        <div className={styles.title}>
          <h1>SMART VINIL</h1>
        </div>
        <SmartVinil />
      </div>
    </div>
  );
}

import React from "react";
import styles from "./styles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper.scss";
import Collections from "./index";
import SwiperCore, { Navigation, Pagination } from "swiper";

export default function CollectionSlider() {
  SwiperCore.use([Navigation, Pagination]);
  const newCollections = [
    {
      image:
        "https://loop-markets.directus.app/assets/becadeef-c154-422b-9788-40a6a6944160",
      name: "Never Walk Alone",
      type: "SINGLE",
      price: "$7,99",
      link: "/collection/3287",
    },
    {
      image:
        "https://loop-markets.directus.app/assets/e0e37a6d-3167-49e3-a04d-6828503751c9",
      name: "Fortnigth",
      type: "REMIX",
      price: "$9.99",
      link: "/collection/3261",
    },
    {
      image:
        "https://loop-markets.directus.app/assets/e1835fa0-f7f5-464f-aa89-c7cb63b5c291",
      name: "Sete",
      type: "SINGLE",
      price: "$7,99",
      link: "/collection/3253",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <Swiper
        slidesPerView={3}
        spaceBetween={10}
        navigation={true}
        loop={true}
        autoplay={true}
        className="video__swiper"
        breakpoints={{
          300: {
            slidesPerView: 1,
          },
          570: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3.2,
          },
          1520: {
            slidesPerView: 3.2,
          },
        }}
      >
        {newCollections?.map((collection, index) => (
          <SwiperSlide key={index}>
            <Collections collection={collection} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

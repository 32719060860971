import { useEffect, useRef, useState } from "react";
import styles from "./nfts.module.scss";
import { Link } from "react-router-dom";
import * as api from "app/api/nfts.api";
import { useHook } from "app/hooks/common";
import { IPFSImage, NFT } from "app/core/common";
import Loader from "app/components/Loader";
import { truncate } from "app/helpers/helper";
import { Trans } from "react-i18next";
import { getMyNFTs } from "app/api/nfts.api";
import { use } from "i18next";

export default function ProfileNfts({ containerRef, address, isUserProfile }) {
  const [page, setPage] = useState(1);
  const [myNfts, setMyNfts] = useState([]);
  const [myNFTsLoading, setMyNFTsLoading] = useState(true);
  const [nftPagination, setNftPagination] = useState<any>();

  /* ======================== */
  /* ==== Fetch My NFTs ==== */
  /* ======================== */
  useEffect(() => {
    const fetchUserNFTs = async () => {
      if (address) {
        await getMyNFTs({ address, page }).then((data) => {
          if (Array.isArray(data.nfts)) {
            setMyNfts([...myNfts, ...data.nfts]);
          }
          setNftPagination(data.pagination);
          setMyNFTsLoading(false);
        });
      }
    };
    fetchUserNFTs();
  }, [address, page]);

  const loadMoreNFTs = () => {
    const totalPages = nftPagination?.total_pages > nftPagination?.current_page;
    //console.log("Load More", totalPages);
    if (totalPages) {
      setMyNFTsLoading(true);
      setPage(page + 1); // Increment page number
    }
  };

  /**********************/
  // scroll event callback function
  /**********************/
  const onScroll = () => {
    const { scrollTop, scrollHeight } = document.documentElement;
    //console.log("scrollHeight", scrollHeight);

    if (
      !myNFTsLoading &&
      window.innerHeight + scrollTop >= scrollHeight - 100
    ) {
      return loadMoreNFTs();
    }
    return false;
  };

  /**********************/
  // Scroll Event
  /**********************/
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <>
      {/* <p onClick={() => { window.location.reload() }} className={styles.notification}><Trans>nftMessage</Trans></p> */}
      {isUserProfile && (
        <div>
          <div>
            <h3>My Wallet</h3>
            <p
              className={styles.subHeading}
              style={{ color: "rgb(162, 143, 241)" }}
            >
              Please allow a few minutes for new collectibles to show in your
              wallet.
            </p>
          </div>
          <hr />
        </div>
      )}

      {/* <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        <table>
          <thead>
            <tr>
              <th>Token Name</th>
              <th>Amount</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>FANS</td>
              <td>TBD</td>
              <td>USD $1000</td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <hr />
      <div>
        <div>
          <h3>Collectibles</h3>
        </div>
      </div>

      <div className={styles.nfts}>
        {myNFTsLoading && myNfts.length === 0 ? (
          <Loader />
        ) : (
          myNfts?.map((item, index) => {
            // do a split at the second # to get the nft number

            const nftNumber =
              item?.name.split(" #")[2] || item?.name.split(" #")[1];
            const nftName = item?.name.split(" #")[0];
            return (
              <div
                key={index}
                className={styles.card}
                style={{ position: "relative" }}
              >
                <Link to={`/nftDetail/${item.id}`} key={index}>
                  <div className={styles.courseImage}>
                    <img src={IPFSImage(item?.image, true)} />
                  </div>
                  <div className={styles.details}>
                    <p className={styles.title}>
                      {nftName}{" "}
                      <small
                        style={{
                          fontSize: "14px",
                          color: "#a28ff1",
                          position: "absolute",
                          top: "5px",
                          left: "5px",
                          background: "#000000",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        #{nftNumber}
                      </small>
                    </p>
                    <p className={styles.description}>
                      {truncate(item?.description, 150)}
                    </p>
                  </div>
                </Link>
              </div>
            );
          })
        )}
      </div>

      {myNFTsLoading && myNfts.length > 0 && <Loader />}
    </>
  );
}

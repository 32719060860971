import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import styles from "./Profile.module.scss";
import { isAdmin } from "app/core/common";
import * as api from "app/api/profile.api";
import axios from "axios";
import { useHook } from "app/hooks/common";
import { use } from "i18next";

export default function AdvancedView({ userInfo, data, type }) {
  const { cookie } = useHook();
  const [featured, setFeatured] = useState(false);

  useEffect(() => {
    setFeatured(data?.featured);
  }, [data]);

  /* ======================== */
  /* ==== Handle Updates ==== */
  /* ======================== */
  const handleProfileUpdates = async () => {
    setFeatured(!featured);

    // Update the user's profile
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}admin/action/update_user/${data.id}`,
      data: {
        cookie: cookie,
        field_name: "featured",
        field_value: !featured,
      },
    });
  };

  /* ======================== */
  /* ==== Handle Updates ==== */
  /* ======================== */
  const handleCollectionUpdates = async () => {
    setFeatured(!featured);

    // Update the collection
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}admin/action/update_collection/${data.id}`,
      data: {
        cookie: cookie,
        field_name: "featured",
        field_value: !featured,
      },
    });
  };

  /* ======================== */
  /* ==== Handle Logout ==== */
  /* ======================== */

  return (
    isAdmin(userInfo?.role) && (
      <>
        <div
          style={{
            background: "#ffffff0f",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <>
            <div className={styles.admin}>
              <b
                style={{
                  color: "#30a2fa",
                }}
              >
                Advanced View
              </b>
              <ul>
                {type === "artist" && (
                  <li>
                    <Checkbox
                      checked={featured}
                      onChange={handleProfileUpdates}
                      color="info"
                      style={{ color: "#fff" }}
                    />{" "}
                    Featured Artist
                  </li>
                )}
                {type === "collection" && (
                  <li>
                    <Checkbox
                      checked={featured}
                      onChange={handleCollectionUpdates}
                      color="info"
                      style={{ color: "#fff" }}
                    />{" "}
                    Featured Collection
                  </li>
                )}
              </ul>
            </div>
          </>
        </div>
        <hr />
      </>
    )
  );
}

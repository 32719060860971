import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useHook } from "app/hooks/common";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { truncate } from "app/helpers/helper";

export default function Collections({
  collection,
  index,
}: {
  collection?: any;
  index?: number;
}) {
  return (
    <Link to={collection?.link} className={styles.creators_container}>
      {/* {index === 2 && <div className={styles.banner}>Coming Soon</div>} */}

      <LazyLoadImage
        src={collection?.image}
        alt="collections-loop-fans"
        effect="blur"
      />

      <div className={styles.trackinfo}>
        <p>{collection?.name}</p>
        <p>{collection.type}</p>
        <p>{collection.price}</p>
      </div>
    </Link>
  );
}

import React from "react";
import styles from "./styles.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function SmartVinil() {
  const halfvinil =
    "https://loop-markets.directus.app/assets/680f1440-9e73-46e2-b952-efb1947bd4c1";

  const vinilCover =
    " https://loop-markets.directus.app/assets/9252d326-365a-4383-a881-7cd649ef4df7";

  const artisVinilPhoto =
    "https://loop-markets.directus.app/assets/64b6be2e-2304-4798-875e-7f74d54936e1";

  return (
    <div className={styles.smartvinil}>
      <div className={styles.smartvinil__vinil}>
        <LazyLoadImage
          src={vinilCover}
          alt="collections-loop-fans"
          effect="blur"
          wrapperClassName={styles.image}
        />
      </div>
      <div className={styles.smartvinil__half}>
        <img src={halfvinil} alt="vinil" />
      </div>
      <div className={styles.smartvinil__text}>
        <div>
          <p>BLOND:ISH'S 2015</p>
          <p>WELCOME TO THE PRESENT</p>
          <p>SMART VINIL EDITION:</p>
        </div>
        <div className={styles.middle}>
          <span>Includes</span>
        </div>
        <div>
          <ul>
            <li>6 New Remixes</li>
            <li>3 Never Seen Video</li>
            <li>Photo Gallary</li>
          </ul>
        </div>
      </div>
      <div className={styles.smartvinil__artisImage}>
        <LazyLoadImage
          src={artisVinilPhoto}
          alt="collections-loop-fans"
          effect="blur"
          wrapperClassName={styles.image}
        />
      </div>
    </div>
  );
}
